<template>
  <tr :class="{ 'is-busy': isDeleting }">
    <td @click="$emit('update', circuit)" class="align-middle" role="button">
      <div>{{ circuit.name }}</div>
      <div v-if="description" v-html="description" class="smaller text-muted"></div>
    </td>
    <td class="align-middle">
      <div v-if="resources.length" class="flex flex-column">
        <div
          v-for="(resource, resourceIndex) in resources"
          :key="`key-${resourceIndex}`"
          class="flex-item"
        >
          <VideoTag :item="resource" :dismissible="false"></VideoTag>
        </div>
      </div>
    </td>
    <td class="align-middle text-end">
      <IconButton
        @click="showConfirmModal = true"
        :icon="'trash-alt'"
        :isLoading="isDeleting"
        :disabled="isDeleting"
        class="btn btn-sm btn-danger"
      ></IconButton>

      <portal to="modal">
        <transition name="modal">
          <ConfirmModal
            @confirm="deleteCircuit"
            @close="showConfirmModal = false"
            v-model="showConfirmModal"
            v-if="showConfirmModal"
          ></ConfirmModal>
        </transition>
      </portal>
    </td>
  </tr>
</template>

<script>
import { mapState } from 'vuex';
import { httpDelete } from '@/core/http';

export default {
  name: 'CircuitListItem',
  components: {
    VideoTag: () => import('@/elements/VideoTag'),
    IconButton: () => import('@/components/button/IconButton'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
  },
  props: {
    circuit: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    ...mapState('auth', ['authUser']),
    description() {
      const { description } = this.circuit;
      if (description && description !== '') return this.$options.filters.nl2br(description);
      return null;
    },
    resources() {
      return this.$options.filters.arrayStringParsed(this.circuit.resource);
    },
  },
  methods: {
    async deleteCircuit() {
      this.showConfirmModal = false;
      this.isDeleting = true;
      try {
        const { circuitLibraryId } = this.circuit;
        await httpDelete('library/circuit', {
          circuitLibraryId,
          authorId: this.authUser.userId,
        });
        this.$emit('delete', circuitLibraryId);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isDeleting = false;
      }
    },
  },
  data() {
    return {
      isDeleting: false,
      showConfirmModal: false,
    };
  },
};
</script>
